<template>
  <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
    <h2 class="mb-4">Support Ticket erstellen:</h2>
    <form
      id="a_support_ticket_form"
      name="a_support_ticket_form"
      @submit.prevent="onSubmit"
      netlify
    >
      <div class="-mx-3 mb-6">
        <div class="w-100 px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
            for="issue-title"
          >
            Titel
          </label>
          <input
            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-400 rounded py-3 px-4 mb-3"
            id="issue-title"
            type="text"
            placeholder="Betreff / Titel"
            required
            v-model="title"
          />
        </div>
      </div>
      <div class="-mx-3 mb-2">
        <div class="px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
            for="issue-description"
          >
            Beschreibung
          </label>
          <textarea
            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
            id="issue-description"
            placeholder="Beschreibung des Problems/Fehlers"
            rows="10"
            required
            v-model="description"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <button type="submit">
          Absenden
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SupportDialog",
  data() {
    return {
      title: "",
      description: ""
    };
  },
  methods: {
    onSubmit() {
      axios
        .post(
          "https://schleifmitteldirekt.myjetbrains.com/youtrack/api/issues",
          {
            project: {
              id: "0-1"
            },
            summary: this.title,
            description: this.description
          },
          {
            headers: {
              Authorization:
                "Bearer perm:cm9vdA==.NDYtMA==.zGrOmEBokdaFazcN1xVotiLA5V9kVx"
            }
          }
        )
        .then(res => {
          console.log(res);
          document.querySelector("#a_support_ticket_form").reset();
        });
    }
  }
};
</script>

<style scoped lang="scss">
input,
select,
textarea {
  background: #f9fafb;
  border: 1px solid #d1d5db;
}
</style>
