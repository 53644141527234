<template>
  <div class="w-full">
    <h1 class="font-weight-bold mb-4">Support / Hilfe</h1>
    <support-dialog />
  </div>
</template>

<script>
import SupportDialog from "@/components/SupportDialog.vue";
export default {
  name: "Support",
  components: { SupportDialog }
};
</script>

<style scoped></style>
